@import '../../../styles/variables';
@import '../../../styles/mixins';

.publicSidebarContainer {
  background: $color-grey-blue;
  width: 90%;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @include bp(m) {
    padding: 2rem 2.125rem 1.0625rem 2.5rem;
    display: flex;
    justify-content: space-between;
    width: 20.5625rem;
    flex-direction: column;
    min-height: 100vh;
  }

  .topSection {
    .missingProfileInfoContainer {
      margin-bottom: 2.12rem;
      font-family: $font-manrope;
      font-style: normal;
      line-height: normal;
      font-weight: 500;
      font-size: 0.9375rem;

      .messageContainer {
        padding: 0.44rem 2.69rem 0.69rem 2.62rem;
        text-align: center;
        background-color: $color-white;
        border-radius: 0.5rem;
        color: $red-color-more-darker;
      }

      .fillProfileInfo {
        display: block;
        margin-top: 0.69rem;
        text-align: center;
        color: $primary-main;
        text-decoration-line: underline;
        transition: 0.2s;

        &:hover {
          color: $primary-dark;
        }
      }
    }
  }

  .bottomSection {
    width: 100%;

    .becomeOrganiser {
      width: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: 1.125rem;
      background-color: $primary-main;
      color: $color-white;
      font-size: 1.0625rem;
      font-weight: 400;
      border-radius: 0.375rem;
      padding: 1rem 0 1rem 1.75rem;
      cursor: pointer;
      user-select: none;
      width: 15.9375rem;
      margin-bottom: 0.25rem;

      &:hover {
        background: $primary-main-lighter;
      }

      div {
        width: 1.5625rem;
        display: flex;
        align-items: center;

        svg {
          width: 100%;
        }
      }
    }

    .roleSelectionContainer {
      margin-top: 1.5625rem;
      margin-bottom: 0.5rem;

      :global(.MuiAutocomplete-input) {
        pointer-events: none;
      }

      :global(.MuiOutlinedInput-root) {
        background-color: transparent;
        border: none;
        color: $primary-main;
        font-weight: 500;
        font-size: 1.0625rem;
        padding-left: 0 !important;
        cursor: pointer;

        ::selection {
          background: transparent;
        }

        input {
          cursor: pointer;
          padding-left: 1.875rem !important;
        }
      }
    }

    .profileContainer {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1.25rem;
      margin-bottom: 2.3125rem;
      cursor: pointer;

      .profileInfo {
        display: flex;
        flex-direction: column;
        word-break: break-all;
        padding-left: 1.875rem;

        .profileName {
          color: $primary-main;
          font-size: 1rem;
          font-weight: 700;
          text-decoration-line: underline;

          &:hover {
            color: $primary-dark;
          }
        }

        .profileEmail {
          color: $profile-email-text;
          font-size: 0.8125rem;
          font-weight: 400;
        }
      }
    }

    .missingProfileInfoContainer {
      margin-bottom: 2.12rem;
      font-family: $font-manrope;
      font-style: normal;
      line-height: normal;
      font-weight: 500;
      font-size: 0.9375rem;
      display: flex;
      flex-direction: column;
      gap: 0.4375rem;

      .messageContainer,
      .messageContainerInfo {
        padding: 0.44rem 2.69rem 0.69rem 2.62rem;
        text-align: center;
        background-color: $color-white;
        border-radius: 0.5rem;
        color: $red-color-more-darker;
        cursor: pointer;
        border: 1px solid $color-white;

        &:hover {
          border: 1px solid $input-border;
        }
      }

      .messageContainerInfo {
        color: $warning-color;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .fillProfileInfo {
        display: block;
        margin-top: 0.69rem;
        text-align: center;
        color: $primary-main;
        text-decoration-line: underline;
        transition: 0.2s;

        &:hover {
          color: $primary-dark;
        }
      }
    }

    .bottomItems {
      display: flex;
      justify-content: space-between;

      .version {
        font-family: $font-manrope;
        font-weight: 400;
        color: $grey-text;
      }
    }
  }
}

.popper {
  border: 0.0625rem solid $color-light-grey;
  border-radius: 0.25rem;

  * {
    font-family: $font-manrope !important;
    font-size: 1.0625rem;
    font-weight: 400;
    color: $grey-text;
  }

  :global(.MuiAutocomplete-option[aria-selected='true']) {
    background-color: $primary-main-background !important;
    color: $primary-main;
    font-weight: 500;
  }
}

.logo {
  width: 14.1875rem;
}
